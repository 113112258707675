import { constants } from "../constants";

const initialState = {
  defaultPlans: {
    canadaPostpaid: [
      {
        planId: 985,
        plan_fee: 25,
        data: "Voice",
        carrier: "Rogers",
      },
      {
        planId: 986,
        plan_fee: 35,
        data: 4,
        carrier: "Rogers",
      },
      {
        planId: 987,
        plan_fee: 45,
        data: 8,
        carrier: "Rogers",
      },
      {
        planId: 988,
        plan_fee: 55,
        data: 15,
        carrier: "Rogers",
      },
      {
        planId: 989,
        plan_fee: 65,
        data: 18,
        carrier: "Rogers",
      },
    ],
    canadaPrepaid: [
      {
        planId: 990,
        plan_fee: 25,
        data: "Voice",
        carrier: "Rogers",
        PlanTypeD: "30",
      },
      {
        planId: 991,
        plan_fee: 35,
        data: 2,
        carrier: "Telus",
        PlanTypeD: "30",
      },
      {
        planId: 992,
        plan_fee: 45,
        data: 6,
        carrier: "Telus",
        PlanTypeD: "30",
      },
      {
        planId: 994,
        plan_fee: 55,
        data: 12,
        carrier: "Rogers",
        PlanTypeD: "30",
      },
      {
        planId: 995,
        plan_fee: 65,
        data: 15,
        carrier: "Rogers",
        PlanTypeD: "30",
      },
    ],
    usaPrepaidLyca: [
      {
        planId: 1028,
        plan_fee: 19,
        data: 2,
        carrier: "Lyca",
        PlanTypeD: "30",
      },
      {
        planId: 1029,
        plan_fee: 23,
        data: 3,
        carrier: "Lyca",
        PlanTypeD: "30",
      },
      {
        planId: 1030,
        plan_fee: 29,
        data: 7,
        carrier: "Lyca",
        PlanTypeD: "30",
      },
      {
        planId: 1031,
        plan_fee: 33,
        data: 15,
        carrier: "Lyca",
        PlanTypeD: "30",
      },
      {
        planId: 1033,
        plan_fee: 39,
        data: 15,
        carrier: "Lyca",
        PlanTypeD: "30",
      },
      {
        planId: 1034,
        plan_fee: 50,
        data: 40,
        carrier: "Lyca",
        PlanTypeD: "30",
      },
      {
        planId: 1035,
        plan_fee: 59,
        data: 60,
        carrier: "Lyca",
        PlanTypeD: "30",
      },
    ],
  },
};

const defaultPlansReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.RETURN_DEFAULT_PLANS:
      return {
        ...state,
        defaultPlan: action.payload,
      };
    case constants.RESET_DEFAULT_PLANS_STATE:
      return initialState;
    default:
      return state;
  }
};

export default defaultPlansReducer;
