export const constants = {
  // Shopping
  SAVE_SHOP_ITEM: "SAVE_SHOP_ITEM",

  // Object with client details to pass into API
  UPDATE_SHOP_OBJECT: "UPDATE_SHOP_OBJECT",
  UPDATE_SHOP_OBJECT_PREPAID: "UPDATE_SHOP_OBJECT_PREPAID",
  UPDATE_IS_POSTPAID: "UPDATE_IS_POSTPAID",

  ADD_TO_CART: "ADD_TO_CART",
  REMOVE_FROM_CART: "REMOVE_FROM_CART",
  ADJUST_ITEM_QTY: "ADJUST_ITEM_QTY",
  RESET_SHOP_STATE_POSTPAID: "RESET_SHOP_STATE_POSTPAID",
  RESET_SHOP_STATE_PREPAID: "RESET_SHOP_STATE_PREPAID",
  RESET_SHOP_STATE: "RESET_SHOP_STATE",
  UPDATE_COUNTRIES_OBJECT: "UPDATE_COUNTRIES_OBJECT",

  // Return default plans
  RETURN_DEFAULT_PLANS: "RETURN_DEFAULT_PLANS",
  RESET_DEFAULT_PLANS_STATE: "RESET_DEFAULT_PLANS_STATE",

  // Return plans
  RETURN_PLANS: "RETURN_PLANS",
  RESET_PLANS_STATE: "RESET_PLANS_STATE",
  UPDATE_PLANS_STATE: "UPDATE_PLANS_STATE",

  // Get Country Code API
  GET_COUNTRY_CODES: "GET_COUNTRY_CODES",

  // Routes
  ROUTE_PATH: "ROUTE_PATH",
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",

  // Countries
  CANADA: "Canada",
  USA:"USA",
  INTERNATIONAL:"International",

  // Currencies
  CANADA_CURRENCY: "CAD",
  USA_CURRENCY: "USD",

  // payment plan
  PREPAID_PLAN: "Prepaid",
  POSTPAID_PLAN: "Postpaid",
  DATA_PLAN:"DATA",
  DATA_ONLY_PLAN: "Data"
};
