import { constants } from "../constants";
interface areaPlans {
  Prepaid: any[];
  Postpaid: any[];
  data: any[];
}

let initialState = {
  "Canada": {
    Prepaid: [],
    Postpaid: [],
    Data: [],
  },
  "USA": {
    Prepaid: [],
    Postpaid: [],
    Data: [],
  },
};

let persistState: Map<string, areaPlans> = null;

const plansReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.RETURN_PLANS:
      return {
        ...state,
        defaultPlan: action.payload,
      };

    case constants.RESET_PLANS_STATE:
      initialState.Canada.Prepaid = [];
      initialState.Canada.Postpaid = [];
      initialState.Canada.Data = [];
      initialState.USA.Prepaid = [];
      initialState.USA.Postpaid = [];
      initialState.USA.Data = [];
      return initialState;

    case constants.UPDATE_PLANS_STATE:
      return {
        ...state,
        [Object.keys(action.payload)[0]]:
          action.payload[Object.keys(action.payload)[0]]
      };
    default:
      return state;
  }
};

export default plansReducer;
