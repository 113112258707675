import { constants } from "../constants";

const initialState = {
  routeString: "",

  // global bool flag to check if user is logged in or not
  isLoggedIn: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case constants.ROUTE_PATH:
      return {
        ...state,
        routeString: action.payload,
      };
    case constants.LOGIN:
      return {
        ...state,
        isLoggedIn: true,
      };
    case constants.LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
      };
    default:
      return state;
  }
}
