import { PAYMENT_METHOD_CREDIT_CARD } from "../../utilities/constants";
import { constants } from "../constants";

const initialState = {
  // https://developer.gophonebox.com/APIReference/ActivationServices/PostActivationInfoPostpaid.html#request-body
  // Postpaid POST data

  // https://developer.gophonebox.com/APIReference/ActivationServices/PostActivationInfoPrepaid.html#request-body
  // Prepaid POST data
  // Only difference between these two is postpaid has prorate related fields but prepaid doesn't

  // SIM Card Related (2)
  simcardOrder: false,
  simcard_no: "",

  // Service Related (10)
  // The StartDateLocal is to present and functioning on UI
  // The startDate is the utc timezone, which will be stored in Database.
  // the same as endDateLocal and endDate.
  startDateLocal: null,
  endDateLocal: null,
  startDate: "",
  endDate: "", // Leave empty (use "")
  serviceType: "", // Leave empty (use "")
  service_countryId: 42, // Service country ID, must use 42
  service_province: "",
  service_province_fullname: "Select a Province",
  service_city: "",
  firstName: "",
  lastName: "",
  email: "",
  emailConfirm: "",
  data: 0,
  planTypeD: 0,
  country: "",
  usSimcardNoCharge: false,
  usPromocode: "",
  noTopUpFee: false,
  paymentMade: false,
  consent_cem: false,

  // Port-In Related (4) - For below services, leave empty ("") if not port-in.
  portin_carrier_local: "Select a Carrier",
  portin_accountNo_local: "",
  portin_phoneNo_local: "",
  portin_other_local: "",
  portin_imei_local: "",
  portin_carrier: "",
  portin_accountNo: "",
  portin_phoneNo: "",
  portin_other: "",
  // add imei
  portin_imei: "",
  // Plan Related (4)
  planId: 0,
  plan_fee: 0,
  promocode: "", // POSTPAID
  prepaidPromocode: "",
  planCarrier: "",
  plans: [],
  referral_cnum: "",
  pfCode: "",
  fromPartner: false,
  fromJpPartner: false,
  fromSpecialLanguagePartner: false,

  // Prorate Related (4)
  prorateDataCap: "",
  prorateStartDate: "",
  prorateEndDate: "",
  proratePct: 0,
  prorate_duration: "",

  // Fee Related (11)
  currency: constants.CANADA_CURRENCY,
  simcard_fee: 0.0,
  prorate_fee: 0.0,
  charge_duration: "",
  gst_rate: 0.0,
  pst_rate: 0.0,
  gst_amt: 0.0,
  pst_amt: 0.0,
  subtotal: 0.0,
  promocredit: 0.0,
  total: 0.0,
  chargeStart: "",
  us_tax_rate: 0.0,
  us_tax_amount: 0.0,

  //local shipment related
  shipping_unitNo: "",
  shipping_streetAddress: "",
  shipping_city: "",
  shipping_stateProvince: "",
  shipping_country: "Canada",
  shipping_countryIndex: 42,
  shipping_zipPostal: "",
  shipping_fullAddress: "",
  shipping_deliLongest: 0,
  shipping_contact_number: "",

  // Shipment Related (4)
  delivery_countryId: 0,
  delivery_province: "",
  delivery_address: "",
  delivery_postal: "",
  shippingStreetAddress: "",
  shippingCity: "",
  shippingProvince: "",
  delivery_needed: true,
  delivery_type: 0,
  sfID: 0,
  sfName: "",
  sfFee: 0,
  // ESIM Related (1)
  esim: false,

  isPostpaid: true,
  dataOnly: false,
  oa_paId: "",
  editMode: false,
  showCheckSimModal: false,

  // US products
  us_main_i_product: null,
  us_plintron_plan_id: null,
  us_plan_i_subscription: null,
  us_i_rep: null,

  // portin service address related
  us_portin_service_unitNo: "",
  us_portin_service_street_address: "",
  us_portin_service_city: "",
  us_portin_service_state_province: "",
  us_portin_service_country: "United States",
  us_portin_service_country_index: 247,
  us_portin_service_zip_postal: "",
  us_portin_service_full_address: "",
  us_portin_account_number: "",
  us_portin_pin_number: "",
  us_portin_phone_number: "",
  us_account_shipping_code_country: "",
  us_account_shipping_code_type: "",
  us_portin_is_not_po_box: false,
  us_shipping_i_subscription: null,
  us_activation_date: null,

  // US account creation
  us_i_customer: null,
  us_i_account: null,

  // US Add ons
  selected_intl_add_on: null,

  // Country info
  countries: [],
  payment_method: PAYMENT_METHOD_CREDIT_CARD,

  // maintenance status
  maintenance_status: "",
};

const shopReducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SAVE_SHOP_ITEM:
      return {
        ...state,
        state: action.payload,
      };
    case constants.UPDATE_SHOP_OBJECT:
      return {
        ...state,
        [Object.keys(action.payload)[0]]:
          action.payload[Object.keys(action.payload)[0]],
      };
    case constants.UPDATE_IS_POSTPAID:
      return {
        ...state,
        isPostpaid: action.payload,
      };
    case constants.RESET_SHOP_STATE:
      return initialState;
    default:
      return state;
  }
};

export default shopReducer;
